import { render, staticRenderFns } from "./ItemModal.vue?vue&type=template&id=f1e493bc&scoped=true&"
import script from "./ItemModal.vue?vue&type=script&lang=js&"
export * from "./ItemModal.vue?vue&type=script&lang=js&"
import style0 from "./ItemModal.vue?vue&type=style&index=0&id=f1e493bc&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-8057611e5a/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1e493bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../.yarn/__virtual__/vuetify-loader-virtual-ed9205d01e/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDialog,VIcon,VSpacer})
