<template>
  <v-dialog
    v-model="model"
    transition="scale-transition"
    :min-width="300"
    :max-width="600"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      :style="`border-top-color: ${color};`"
      class="item-card elevation-3"
      :loading="!item"
    >
      <v-card-title v-if="item">
        <v-icon
          :color="color"
          class="mr-2"
        >
          {{ icon }}
        </v-icon>

        <span>
          {{ item.name }}
        </span>

        <v-spacer />

        <span>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text v-if="item">
        <p v-if="item.description">
          <nl2br
            :text="item.description"
            tag="p"
          />
        </p>
        <p
          v-else
          class="text--secondary font-italic text-center"
        >
          {{ $t('messages.views.public.components.dashboard.itemModel.noDescription') }}
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    props: {
      itemId: {
        type: [String, Number],
        required: true,
      },
    },

    apollo: {
      item: {
        query: require('@gql/views/public/components/dashboard/itemModal/getItem.gql'),
        client: 'information',
        fetchPolicy: 'no-cache',
        variables: function () {
          return {
            id: this.itemId,
          };
        },
        update: function (data) {
          return data.getItem;
        },
      },
    },

    data: () => ({
      model: false,
    }),

    computed: {
      color () {
        if (!this.item) {
          return '#ccc';
        }

        return '#' + (this.item.color ? this.item.color : this.item.category.color);
      },
      icon () {
        if (!this.item) {
          return null;
        }

        return 'mdi-' + (this.item.icon ? this.item.icon : this.item.category.icon);
      },
    },

    watch: {
      model: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit('close');
        }
      },
    },

    mounted () {
      this.model = this.itemId !== null;
    },
  };
</script>

<style scoped lang="sass">
  .item-card
    border-top-width: 5px
    border-top-style: solid
</style>
